<template>
    <div class="col-md-6">
        <div class="content-item">
            <small>{{ title }}</small>
            <h3>{{ subtitle }} 
                <a :href="url" target="_blank" rel="noopener noreferrer">
                    <i aria-hidden="true" class="fa fa-external-link"></i>
                </a>
            </h3>
            <h4>{{ description }}</h4>

            <p>{{ aditional }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DataSheet',
    props: {
        title: String,
        subtitle: String,
        description: String,
        aditional: String,
        url: String
    }
}
</script>
