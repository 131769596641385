<template>
        <div id="preloader">
            <div id="status">
                <div class="status-mes"></div>
            </div>
        </div>
</template>

<script>
export default {
  name: 'Preloader'
}
</script>