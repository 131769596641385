<template>
<li class="list-inline-item event-list">
    <div class="px-4">
        <div v-bind:class="'event-date bg-soft-' + cls">{{ date }}</div>
        <h5 class="font-size-16">{{ description }}</h5>
        <p class="font-size-10">{{ title }}</p>
        <p class="text-muted">{{ location }}</p>
    </div>
</li>
</template>
<script>
export default {
    name: 'TimeLineItem',
    props: {
        date: String,
        title: String,
        description: String,
        location: String,
        cls: String
    }
}
</script>
<style scoped>
.hori-timeline .events .event-list {
    display: block;
    position: relative;
    text-align: center;
    padding-top: 70px;
    margin-right: 0;
}
.hori-timeline .events .event-list:before {
    content: "";
    position: absolute;
    height: 36px;
    border-right: 2px dashed #dee2e6;
    top: 0;
}
.hori-timeline .events .event-list .event-date {
    position: absolute;
    top: 38px;
    left: 0;
    right: 0;
    width: 75px;
    margin: 0 auto;
    border-radius: 4px;
    padding: 2px 4px;
}
@media (min-width: 1140px) {
    .hori-timeline .events .event-list {
        display: inline-block;
        width: 24%;
        padding-top: 45px;
    }
    .hori-timeline .events .event-list .event-date {
        top: -12px;
    }
}
@media (max-width: 768px) {
    .hori-timeline .events .event-list .event-date {
        top: 28px;
    }
}
.bg-soft-primary {
    color: #ffffff;
    font-weight: bold;
    background-color: #1769aa !important;
}
.bg-soft-disable {
    background-color: #e9ecef !important;
}
</style>