<template>
<div class="hori-timeline" dir="ltr">
    <ul class="list-inline events">
        <slot></slot>
    </ul>
</div>
</template>
<script>
export default {
    name: 'TimeLine'
}
</script>
<style scoped>
.hori-timeline .events {
    border-top: 3px solid #e9ecef;
}
</style>