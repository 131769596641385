<template>
    <figure class="figure text-center skill">
        <img class="img-fluid" v-bind:src="'/assets/img/skills/' + image" v-bind:alt="skill">
        <figcaption class="figure-caption">{{ title }}</figcaption>
    </figure>
</template>

<script>
export default {
    name: 'SkillItem',
    props: {
        skill: String,
        title: String,
        image: String
    }
}
</script>

<style scoped>
.skill {
    margin-right: 1rem;
}
</style>
