<template>
    <div class="col-md-6">
        <div class="progress-wrapper">
            <h3>{{ title }}</h3>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FreeSlot',
    props: {title: String}
}
</script>

<style scoped>
.progress-wrapper {
    margin-bottom: 35px;
}
</style>