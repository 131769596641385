import Vue from 'vue';
import App from './App.vue';
import Router from './routes.js';

Vue.config.productionTip = false;

Vue.prototype.$cleanString = function(string) {
  return string.replace(/[\s/]/g, '')
}

Vue.prototype.$isMobile =  function() {
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}

const router = Router.config
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
