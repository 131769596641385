<template>
        <div class="col-md-6">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDisabled" checked disabled>
                <label class="form-check-label" for="flexCheckDisabled">
                    {{ title }}
                </label>
            </div>
        </div>
</template>

<script>
export default {
    name: 'ProgressItem',
    props: {
        title: String
    }
}
</script>
<style>
.form-check-input:checked{
    opacity: 1;
}
</style>