<template>
    <div :class="clss">
        <div class="expertise-item">
            <h3>{{ title }}</h3>
            <p>
                {{ description }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Resume',
    props: {
        clss: String,
        title: String,
        description: String
    }
}
</script>