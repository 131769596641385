<template>
    <div class="profile-img">
        <img :src="img" class="img-fluid" alt=""/>
    </div>
</template>

<script>
export default {
  name: 'ProfileImage',
  props: {
    img: String
  }
}
</script>