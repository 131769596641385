<template>
    <div class="col-md-4 col-sm-6">
        <a class="portfolio-item" :href="url" target="_blank">
            <div class="portfolio-thumb">
                <img :src="image" alt="" class="img-thumbnail thumb-center">
            </div>

            <div class="portfolio-info information text-center">
                <h3>{{ role }}</h3>
                <small>{{ enterprise }}</small>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    name: 'Portfolio',
    props: {
        url: String,
        image: String,
        role: String,
        enterprise: String
    }
}
</script>

<style>

.portfolio-item::after{
    background: transparent;
}

.information {
    display: none;
}

.portfolio-item:hover > .information{
    display: block;
    transition: background 0.2s ease;
    background: #f8f8f8;
}

.information > h3 {
    color: #1769aa !important;
}

.thumb-center{
    display: block;
    margin: 0 auto;
}
</style>