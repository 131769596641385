import Vue from 'vue';
import VueRouter from 'vue-router'
import App from './App.vue'

Vue.use(VueRouter)

class Router {

    constructor(){
        const routes = this.loadRoutes();
        this.config = new VueRouter({
            routes
        })
    }

    loadRoutes(){
        const routes = [
            { path: '/', name: 'home', component: App },
            { path: '/uses', name: 'uses', component: App }
        ]
        return routes;
    }
}

export default Router;