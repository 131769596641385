<template> 
    <li><a :href="url" target="_blank"><i :class="clss" aria-hidden="true"></i></a></li>
</template>

<script>
export default {
    name: 'SocialIcon',
    props: {
        url: String,
        clss: String
    }
}
</script>