<template>
    <section class="section-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <div class="section-title">
                        <h2>{{ title }}</h2>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="row">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'SectionWrapper',
    props: {'title': String},
}
</script>