<template>
    <div class="col-md-3">
        <div class="personal-details">
            <strong>{{ value }}</strong>
            <small>{{ detail }}</small>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PersonalDetail',
    props: {
      value: String,  
      detail: String,
    }
}
</script>