<template>
    <div id="app">
        <div id="main-wrapper">
            <Preloader></Preloader>
            <!-- .header-->    
            <header class="header">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <ProfileImage
                                v-bind:img="imgProfile">
                            </ProfileImage>
                        </div>
                        <div class="col-md-9">
                            <Description
                                v-bind:name="name"
                                v-bind:title="title"
                                v-bind:description="description">
                            </Description>

                            <div class="row">
                                <PersonalDetail
                                    v-for="item in personalDetail"
                                    v-bind:detail="item.detail"
                                    v-bind:value="item.value"
                                    v-bind:key="item.id">
                                </PersonalDetail>
                            </div>
            
                            <ul class="social-icon">
                                <SocialIcon
                                    v-for="item in networks"
                                    v-bind:url="item.url"
                                    v-bind:clss="item.clss"
                                    v-bind:key="item.id">
                                </SocialIcon>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            <!-- .header-->
            
            <!-- .specialities-wrapper -->
            <SectionWrapper
                v-bind:title="specialities.title">
                <Resume
                    v-for="item in specialities.list"
                    v-bind:clss="item.clss"
                    v-bind:title="item.title"
                    v-bind:description="item.description"
                    v-bind:key="item.id">
                </Resume>
            </SectionWrapper>
            <!-- .specialities-wrapper -->
            
            <SectionWrapper
                v-bind:title="workExperience.title">
                    <TimeLine>
                        <TimeLineItem
                            v-for="item in workExperience.list"
                            v-bind:date="item.date"
                            v-bind:title="item.role"
                            v-bind:description="item.description"
                            v-bind:location="item.location"
                            v-bind:cls="item.cls"
                            v-bind:key="item.id">
                        </TimeLineItem>
                    </TimeLine>
            </SectionWrapper>

            <!-- .skill-wrapper -->
            <SectionWrapper
                v-bind:title="skill.title">
                    <FreeSlot
                        v-for="skl in skill.list"
                        v-bind:title="skl.title"
                        v-bind:key="skl.id">
                            <SkillItem
                                v-for="item in skl.list"
                                v-bind:skill="item.title"
                                v-bind:title="item.title"
                                v-bind:image="item.image"
                                v-bind:key="item.id">
                            </SkillItem>
                    </FreeSlot>
            </SectionWrapper>
            <!-- .skill-wrapper -->
            

            <!-- .knowledge-wrapper -->
            <SectionWrapper
                v-bind:title="knowledge.title">
                    <FreeSlot
                        v-for="knw in knowledge.list"
                        v-bind:title="knw.title"
                        v-bind:key="knw.id">
                            <ProgressItem
                                v-for="item in knw.list"
                                v-bind:title="item.title"
                                v-bind:key="item.id">
                            </ProgressItem>
                    </FreeSlot>
            </SectionWrapper>

            <!-- .section-education -->
            <SectionWrapper
                v-bind:title="education.title">
                    <DataSheet
                        v-for="item in education.list"
                        v-bind:title="item.date"
                        v-bind:subtitle="item.carrier"
                        v-bind:description="item.university"
                        v-bind:aditional="item.location"
                        v-bind:key="item.id">
                    </DataSheet>      
            </SectionWrapper>
            <!-- .section-education -->

            <!-- .section-certs -->
            <SectionWrapper
                v-bind:title="certs.title">
                    <DataSheet
                        v-for="item in certs.list"
                        v-bind:title="item.date"
                        v-bind:subtitle="item.carrier"
                        v-bind:description="item.university"
                        v-bind:aditional="item.location"
                        v-bind:url="item.url"
                        v-bind:key="item.id">
                    </DataSheet>  
            </SectionWrapper>
            <!-- .section-certs -->
            
            <!-- .portfolio -->
            <SectionWrapper
                v-bind:title="portfolio.title">
                <Portfolio
                    v-for="item in portfolio.list"
                    v-bind:url="item.url"
                    v-bind:image="item.img"
                    v-bind:role="item.role"
                    v-bind:enterprise="item.enterprise"
                    v-bind:key="item.id">
                </Portfolio>
            </SectionWrapper>
            <!-- .portfolio -->
            
            <SectionWrapper
                v-bind:title="contactData.title">
                <div class="col-md-3">
                    <address>
                        <strong>Email</strong><br>
                        <a href="mailto:#">{{ contactData.email }}</a>
                    </address>
                </div>

            </SectionWrapper>
        </div>
        <!-- #main-wrapper -->
    </div>
</template>

<script>
import DataSheet from './components/DataSheet'
import Description from './components/Description'
import PersonalDetail from './components/PersonalDetail'
import Portfolio from './components/Portfolio'
import Preloader from './components/Preloader'
import ProfileImage from './components/ProfileImage'
import ProgressItem from './components/ProgressItem'
import SkillItem from './components/SkillItem'
import Resume from './components/Resume'
import SectionWrapper from './components/SectionWrapper'
import FreeSlot from './components/FreeSlot'
import SocialIcon from './components/SocialIcon'
import TimeLine from './components/TimeLine'
import TimeLineItem from './components/TimeLineItem'
import data  from './app.json'

export default {
  name: 'app',
  components: {
    DataSheet,
    Description,
    PersonalDetail,
    Portfolio,
    Preloader,
    ProfileImage,
    ProgressItem,
    SkillItem,
    Resume,
    SectionWrapper,
    FreeSlot,
    SocialIcon,
    TimeLine,
    TimeLineItem
  },
  data() {
    return data
  }
}
</script>